export enum NotificationType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export interface NotificationsOptions {
    text?: string
    type: NotificationType,
    duration?: number,
}

export interface Notification extends Required<Omit<NotificationsOptions, 'duration'>> {
    id: string,
}
