import emitter from './emitters';
import {NotificationsOptions, NotificationType} from './types';

const add = (args: NotificationsOptions): void => {
    emitter.emit('add', args);
};

export const addError = (text: string): void => {
    add({
        text,
        type: NotificationType.ERROR,
    });
};

export const addSuccess = (text: string, durationParam: number): void => {
    add({
        text,
        type: NotificationType.SUCCESS,
        duration: durationParam
    });
};

export const addSuccessAndRedirect = (text: string, redirectMessage: string, notificationTimeOut: number, timeOut: number): void => {
    addSuccess(text, notificationTimeOut);
    setTimeout(() => {
        addSuccess(redirectMessage, notificationTimeOut);
    }, timeOut);
};