<template>
  <div class="notify">
    <transition-group name="notification">
      <notification-item
          v-for="item in notifications"
          :key="item.id"
          :notification-item="item"
          @close="closeNotification"
          @hover="handleHover"
          @hover-leave="handleHoverLeave"
      />
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import {ref, onMounted, onUnmounted} from 'vue';
import {nanoid} from 'nanoid';
import {NotificationsOptions, Notification} from './types';
import emitter from './emitters';
import NotificationItem from './NotificationItem.vue';

type NotificationsTimeouts = Record<string, number>;

const DEFAULT_DURATION = 10000;
const notifications = ref<Notification[]>([]);
const notificationsTimers: NotificationsTimeouts = {};

const closeNotification = (id: string) => {
  if (!id) return;
  const index = notifications.value.findIndex((notification) => notification.id === id);
  if (index > -1) {
    notifications.value.splice(index, 1);
  }
};

const addNotification = (item: NotificationsOptions) => {
  if (notifications.value.find((notification) => notification.text === item.text)) {
    return;
  }

  const id = nanoid(10);
  const notification: Notification = {
    id,
    text: item.text || '',
    type: item.type,
  };
  notifications.value.push(notification);
  notificationsTimers[id] = setTimeout(
      () => closeNotification(notification.id),
      item.duration || DEFAULT_DURATION,
  );
};

const handleHover = (id: string) => {
  if (notificationsTimers[id]) {
    clearTimeout(notificationsTimers[id]);
  }
};
const handleHoverLeave = (id: string) => {
  if (notificationsTimers[id]) {
    clearTimeout(notificationsTimers[id]);
  }
  notificationsTimers[id] = setTimeout(() => closeNotification(id), DEFAULT_DURATION);
};

onMounted(() => {
  emitter.on('add', addNotification);
  emitter.on('close', closeNotification);
});

onUnmounted(() => {
  notifications.value = [];
  Object.values(notificationsTimers).forEach((timer) => {
    if (timer) {
      clearTimeout(timer);
    }
  });
});
</script>

<style scoped lang="scss">
.notify {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  z-index: $zindex-notifications;
  top: 64px;
  right: 32px;
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease-in-out;
}

.notification-enter-active {
  transition-delay: 0.25s;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0.001;
  transform: translateX(300px);
}

@media (max-width: 768px) {
  .notify {
    top: 70px;
    right: 20px;
  }
}

@media (max-width: 412px) {
  .notify {
    top: 40px;
    right: 0;
    width: 100%;
  }
}
</style>
