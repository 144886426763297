
<template>
  <router-view></router-view>
  <notifications />
  <LoaderBar :loading="loading" />
  
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Notifications } from '@/components/base/notifications';
import LoaderBar from "@/components/LoaderBar.vue";

const route = useRoute();
const router = useRouter();
const loading = ref<boolean>(false);
let loadingStartTime = 0;  // Track when loading starts

// Watch for route changes and update the document title
watch(
  () => route.meta?.title,
  () => {
    document.title = (route.meta?.title as string) || 'Admin';
  },
  { immediate: true }
);

// Use navigation guards to toggle loading state during route changes
router.beforeEach((to, from, next) => {
  loading.value = true;
  loadingStartTime = Date.now(); // Record the time when loading starts
  next();
});

router.afterEach(() => {
  const minLoadingTime = 300; // Minimum time in ms for the loader to be visible
  const elapsedTime = Date.now() - loadingStartTime;
  
  // If less than 500ms has passed, delay the removal of the loader
  if (elapsedTime < minLoadingTime) {
    setTimeout(() => {
      loading.value = false;
    }, minLoadingTime - elapsedTime);
  } else {
    loading.value = false;
  }
});
</script>


