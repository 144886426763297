import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

import '@/plugins/vee-validate';
import 'flatpickr/dist/flatpickr.min.css';

import {
    Layout,
    Button,
    Spin,
    Result,
    Card,
    FloatButton,
    Divider,
    Col,
    Row,
    Drawer,
    Table,
    Form,
    Input,
    Select,
    DatePicker,
    Checkbox,
    Switch,
    Radio,
    InputNumber,
    Tag, Collapse,
    Modal,
    Badge
} from 'ant-design-vue';

const pinia = createPinia();
createApp(App)
    .use(pinia)
    .use(router)
    .use(Layout)
    .use(Collapse)
    .use(Button)
    .use(Spin)
    .use(Result)
    .use(Card)
    .use(Divider)
    .use(Col)
    .use(Row)
    .use(Drawer)
    .use(Table)
    .use(Form)
    .use(InputNumber)
    .use(Tag)
    .use(Modal)
    .use(Input)
    .use(Select)
    .use(DatePicker)
    .use(Checkbox)
    .use(Radio)
    .use(Switch)
    .use(FloatButton)
    .use(Badge)
    .mount('#app');
